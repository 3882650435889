import React, { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';

const Head = ({ location }) => {
  const { pathname } = location;
  const { site, file } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          author
          siteUrl
          siteRoutesMap {
            title
            url
          }
        }
      }
      file(relativePath: { eq: "media_image.jpg" }) {
        publicURL
      }
    }
  `);

  const { title, description, author, siteUrl, siteRoutesMap } = site.siteMetadata;
  const { title: subTitle = '' } = useMemo(
    () => siteRoutesMap.find(({ url }) => url === pathname) || {},
    [pathname, siteRoutesMap]
  );
  const mediaTitle = useMemo(() => (subTitle ? `${title} | ${subTitle}` : title), [subTitle, title]);

  return (
    <>
      <title>{mediaTitle}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <meta name="title" content={mediaTitle} />
      <meta name="description" content={description} />
      <meta property="og:title" content={mediaTitle} />
      <meta property="og:url" content={siteUrl + pathname} />
      <meta property="og:site_name" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image:alt" content="René Keller Logo" />
      <meta property="og:image" content={siteUrl + file.publicURL} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={author} />
      <meta name="twitter:site" content={siteUrl} />
      <meta name="twitter:title" content={mediaTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={siteUrl + file.publicURL} />
      <link rel="canonical" href={siteUrl + pathname} />
    </>
  );
};

Head.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default Head;
