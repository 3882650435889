import React, { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';

import { useStyles } from './styles';

const Team = ({ isMobile }) => {
  const classes = useStyles(isMobile);
  const { dataJson, allFile /* , site, file */ } = useStaticQuery(graphql`
    {
      allFile(filter: { relativePath: { regex: "/^team/employee-./" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, transformOptions: { cropFocus: CENTER, fit: COVER })
          }
          relativePath
        }
      }
      dataJson {
        teamTile
        team {
          id
          image
          name
          position
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
      file(relativePath: { eq: "Stelleninserat_2021.pdf" }) {
        relativePath
        publicURL
      }
    }
  `);
  const { teamTile, team } = dataJson;
  const items = useMemo(() => {
    const images = allFile.nodes.reduce((acc, { relativePath, childImageSharp }) => {
      acc[relativePath] = childImageSharp.gatsbyImageData;

      return acc;
    }, {});

    return team.map((member) => ({ ...member, image: images[member.image] }));
  }, [allFile.nodes, team]);

  return (
    <div className={classes.container}>
      <p align="center" className={classes.title}>
        {teamTile}
      </p>
      <div className={classes.imageContainer}>
        {items.map(({ id, name, position, image }) => (
          <div key={id} className={classes.image}>
            <GatsbyImage image={image} alt={name} />
            <div className={classes.cover}>
              <p className={classes.name}>{name}</p>
              <p className={classes.position}>{position}</p>
            </div>
          </div>
        ))}
      </div>
      {/* <div className={classes.linkContainer}>
        <p className={`${classes.position} ${classes.linkText}`}>
          Möchten Sie Teil von unserem Team werden?{' '}
          <a
            href={site.siteMetadata.siteUrl + file.publicURL}
            download={file.relativePath}
            rel="noopener noreferrer"
            target="_blank"
            className={classes.link}
          >
            Wir freuen uns auf Ihre Bewerbung.
          </a>
        </p>
      </div> */}
    </div>
  );
};

Team.defaultProps = { isMobile: false };
Team.propTypes = { isMobile: PropTypes.bool };

export default Team;
