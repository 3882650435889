import React, { useState, useCallback, useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Slider from './Slider';
import Viewer from './Viewer';
import { useStyles } from './styles';

const ImageViewer = () => {
  const classes = useStyles();

  const { allFile } = useStaticQuery(graphql`
    {
      allFile(filter: { relativePath: { regex: "/^photos/photo-./" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, transformOptions: { cropFocus: CENTER, fit: COVER })
          }
          relativePath
        }
      }
    }
  `);
  const items = useMemo(
    () =>
      allFile.nodes.map(({ childImageSharp, relativePath }, id) => ({
        id: id + 1,
        image: childImageSharp.gatsbyImageData,
        relativePath,
      })),
    [allFile.nodes]
  );

  const [currentId, setCurrentId] = useState();
  const handleShow = useCallback((event) => {
    setCurrentId(event.currentTarget.id);
  }, []);
  const handleClose = useCallback(() => {
    setCurrentId(null);
  }, []);

  return (
    <>
      <div className={classes.desktopContainer}>
        <Viewer currentId={currentId} onClose={handleClose} items={items} />
        {items.map(({ id, image, relativePath }) => (
          <button type="button" key={id} id={id} className={classes.image} onClick={handleShow} disabled={Boolean(currentId)}>
            <GatsbyImage image={image} alt={relativePath} />
          </button>
        ))}
      </div>
      <Slider items={items} />
    </>
  );
};

export default ImageViewer;
