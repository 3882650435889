import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  desktopContainer: {
    display: 'flex',
    position: 'relative',
    flexWrap: 'wrap',
    padding: 10,
    marginBottom: 150,
    '@media (max-width: 800px)': {
      display: 'none',
    },
  },
  image: {
    width: 'calc(50% - 5px)',
    cursor: 'pointer',
    outline: 'none',
    border: 'none',
    padding: 0,
    '&:nth-child(2n)': {
      margin: [0, 10, 10, 0],
    },
    '&:nth-child(2n+1)': {
      marginBottom: 10,
    },
    '&:disabled': {
      opacity: 0,
    },
  },
});
