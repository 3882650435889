import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  container: {
    display: 'none',
  },
  '@media (max-width: 800px)': {
    container: {
      display: 'block',
      overflow: 'hidden',
      width: '100%',
      maxWidth: '100%',
      background: 'none',
      border: 'none',
      outline: 'none',
      cursor: 'grab',
      '&:active': {
        cursor: 'grabbing',
      },
    },
  },
  slider: {
    display: 'flex',
    position: 'relative',
    left: ({ current }) => `calc(2vw - ${current} * 90vw - ${current} * 2vw)`,
    transition: 'left .5s',
  },
  image: {
    width: '90vw',
    margin: [0, '1vw'],
  },
});
