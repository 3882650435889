import React, { useRef, useState, useCallback } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';

import { useStyles } from './styles';

const Slider = ({ items }) => {
  const startPosition = useRef();
  const [current, setCurrent] = useState(0);
  const classes = useStyles({ current });

  const handleOffset = useCallback(
    (direction) => {
      if (direction === '+') setCurrent(Math.min(current + 1, items.length - 1));
      if (direction === '-') setCurrent(Math.max(current - 1, 0));
    },
    [current, items.length]
  );

  const handleTouchEnd = useCallback(
    (event) => {
      const pageX = event.changedTouches ? event.changedTouches[0].pageX : event.pageX;

      const offset = pageX - startPosition.current;

      if (Math.abs(offset) > 20) handleOffset(offset < 0 ? '+' : '-');

      startPosition.current = null;

      if (!event.changedTouches) document.body.removeEventListener('mouseup', handleTouchEnd);
    },
    [handleOffset]
  );

  const handleTouchStart = useCallback(
    (event) => {
      if (!event.changedTouches) event.preventDefault();

      startPosition.current = event.changedTouches ? event.changedTouches[0].pageX : event.pageX;

      if (!event.changedTouches) document.body.addEventListener('mouseup', handleTouchEnd);
    },
    [handleTouchEnd]
  );

  return (
    <button
      type="button"
      className={classes.container}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onMouseDown={handleTouchStart}
    >
      <div className={classes.slider}>
        {items.map(({ id, image, relativePath }) => (
          <div key={id}>
            <GatsbyImage image={image} className={classes.image} alt={relativePath} />
          </div>
        ))}
      </div>
    </button>
  );
};

Slider.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      image: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]).isRequired)
        .isRequired,
      relativePath: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default Slider;
