import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';

import ArrowBack from '@/assets/svg/ArrowBack';
import ArrowForward from '@/assets/svg/ArrowForward';
import Close from '@/assets/svg/Close';

import { useStyles } from './styles';

const Viewer = ({ items, onClose, currentId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles(isOpen);
  const [currentItem, setCurrentItem] = useState();

  const item = useMemo(() => (currentItem ? items.find(({ id }) => id === currentItem) : null), [currentItem, items]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    onClose();
  }, [onClose]);

  const handleSwitch = useCallback(
    (direction) => () => {
      if (direction === 'next') {
        const next = 1 + currentItem;

        if (next > items.length) {
          setCurrentItem(1);
        } else {
          setCurrentItem(next);
        }
      }
      if (direction === 'back') {
        const back = currentItem - 1;

        if (back <= 0) {
          setCurrentItem(items.length);
        } else {
          setCurrentItem(back);
        }
      }
    },
    [currentItem, items.length]
  );

  useEffect(() => {
    if (currentId) {
      setCurrentItem(Number(currentId));
      setIsOpen(true);
    }
  }, [currentId]);

  return (
    <div className={classes.container}>
      {item && <GatsbyImage image={item.image} className={classes.image} alt={item.relativePath} />}
      <div className={classes.buttons}>
        <button type="button" className={classes.back} onClick={handleSwitch('back')} aria-label="Previous">
          <ArrowBack />
        </button>
        <button type="button" className={classes.forward} onClick={handleSwitch('next')} aria-label="Next">
          <ArrowForward />
        </button>
      </div>
      <button type="button" className={classes.close} onClick={handleClose} aria-label="Close">
        <Close />
      </button>
    </div>
  );
};

Viewer.defaultProps = {
  currentId: null,
};

Viewer.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      image: PropTypes.objectOf(PropTypes.any).isRequired,
      relativePath: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  currentId: PropTypes.string,
};

export default Viewer;
