import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import ImageViewer from '@/components/ImageViewer';
import Team from '@/components/Team';

import { useStyles } from './styles';

const Home = () => {
  const classes = useStyles();
  const { dataJson } = useStaticQuery(graphql`
    {
      dataJson {
        text
        title
      }
    }
  `);

  return (
    <>
      <div className={classes.container}>
        <p className={classes.title}>{dataJson.title}</p>
        <p className={classes.text}>{dataJson.text}</p>
        <ImageViewer />
        <Team />
      </div>
      <Team isMobile />
    </>
  );
};

export default Home;
