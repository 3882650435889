import { createUseStyles } from 'react-jss';

import { colors } from '@/theme';

const { blood, white, grey } = colors;

export const useStyles = createUseStyles({
  container: {
    flex: [1, 1, 'auto'],
    width: '100%',
    marginTop: 41,
    marginLeft: '40%',
    background: white,
    zIndex: 100,
  },
  title: {
    fontFamily: 'FedraSerifAStd-Demi',
    fontSize: 39,
    color: grey[60],
    lineHeight: 1.21,
    margin: [47, 56, 0, 56],
  },
  text: {
    margin: [20, 56],
  },
  '@media (max-width: 1600px)': {
    title: {
      fontSize: 32,
    },
    text: {
      fontSize: 18,
    },
  },
  '@media (max-width: 1400px)': {
    title: {
      fontSize: 28,
    },
    text: {
      fontSize: 16,
    },
  },
  '@media (max-width: 1280px)': {
    title: {
      fontSize: 22,
    },
    text: {
      fontSize: 14,
    },
  },
  '@media (max-width: 800px)': {
    container: {
      marginTop: 0,
      marginLeft: 0,
      background: (path) => (path ? blood : `linear-gradient(180deg, ${blood} 0%, ${blood} 90%, ${white} 90%, ${white} 100%)`),
      paddingTop: 41,
    },
    title: {
      fontSize: 18,
      lineHeight: 1.22,
      margin: [0, 36],
      color: white,
    },
    text: {
      fontSize: 12,
      lineHeight: 1.5,
      margin: [24, 36, 56, 36],
      color: white,
    },
  },
});
