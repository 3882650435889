import { createUseStyles } from 'react-jss';

import { colors } from '@/theme';

const { blood, lightBlood, grey, white /* , black */ } = colors;

export const useStyles = createUseStyles({
  container: {
    display: (isMobile) => (isMobile ? 'none' : 'block'),
    width: '100%',
    paddingBottom: 94,
    '@media (max-width: 800px)': {
      display: (isMobile) => (isMobile ? 'block' : 'none'),
      paddingTop: 71,
      paddingBottom: 20,
    },
  },
  title: {
    fontFamily: 'FedraSerifAStd-Demi',
    fontSize: 39,
    color: grey[60],
    lineHeight: 1.21,
    marginBottom: 20,
  },
  mobileTitle: {
    fontFamily: 'FedraSerifAStd-Demi',
    fontSize: 17,
    color: blood,
    lineHeight: 1.65,
    marginBottom: 20,
  },
  text: {
    margin: [20, 56],
  },
  imageContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'center',
  },
  image: {
    position: 'relative',
    width: '20%',
    margin: [0, 8],
  },
  cover: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 0,
    cursor: 'pointer',
    transition: 'opacity .5s',
    background: lightBlood,
    '&:hover': {
      opacity: 1,
    },
  },
  name: {
    fontFamily: 'Avenir-Heavy',
    color: white,
    lineHeight: 1.27,
    margin: [0, 5],
  },
  position: {
    fontFamily: 'Avenir-Book',
    color: white,
    lineHeight: 1.14,
    margin: [5, 5],
  },
  // linkContainer: {
  //   width: 'calc(80% + 7 * 8px)',
  //   margin: [40, 'auto', 0],
  // },
  // linkText: {
  //   color: black,
  // },
  // link: {
  //   color: black,
  //   '@media (hover)': {
  //     '&:hover': {
  //       color: grey[60],
  //     },
  //   },
  // },
  '@media (max-width: 1600px)': {
    title: {
      fontSize: 32,
    },
    name: {
      fontSize: 18,
    },
    position: {
      fontSize: 18,
    },
  },
  '@media (max-width: 1400px)': {
    title: {
      fontSize: 28,
    },
    name: {
      fontSize: 16,
    },
    position: {
      fontSize: 16,
    },
  },
  '@media (max-width: 1280px)': {
    title: {
      fontSize: 22,
    },
    name: {
      fontSize: 14,
    },
    position: {
      fontSize: 14,
    },
  },
  '@media (max-width: 800px)': {
    imageContainer: {
      flexWrap: 'wrap',
    },
    image: {
      width: '40%',
    },
    cover: {
      position: 'static',
      opacity: 1,
      background: 'none',
    },
    name: {
      color: blood,
      margin: [8, 0, 5, 0],
    },
    position: {
      color: grey[60],
      margin: [0, 0, 10, 0],
    },
    // linkContainer: {
    //   width: 'calc(80% + 2 * 8px)',
    //   margin: [20, 'auto', 20],
    // },
    // linkText: {
    //   color: black,
    // },
  },
});
