import { createUseStyles } from 'react-jss';

import { colors } from '@/theme';

const { white } = colors;

const button = {
  width: '30%',
  border: 'none',
  background: 'none',
  outline: 'none',
  cursor: 'pointer',
  '& svg': {
    width: 30,
    height: 47,
    color: white,
    transition: 'transform .3s',
  },
  '&:hover': {
    '& svg': {
      transform: 'scale(1.3)',
    },
  },
};

export const useStyles = createUseStyles({
  container: {
    position: 'absolute',
    top: -1,
    left: -1,
    zIndex: 10,
    margin: 10,
    width: 'calc(100% - 18px)',
    height: (isOpen) => (isOpen ? 'auto' : 0),
    background: white,
    overflow: 'hidden',
  },
  image: {
    margin: [10, 0],
  },
  buttons: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-between',
    bottom: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
  close: {
    position: 'absolute',
    right: '0.5%',
    top: 'calc(1.5% + 10px)',
    border: 'none',
    background: 'none',
    outline: 'none',
    cursor: 'pointer',
    '& svg': {
      width: 38,
      height: 38,
      color: white,
      transition: 'transform .3s',
    },
    '&:hover': {
      '& svg': {
        transform: 'scale(1.3)',
      },
    },
  },
  back: {
    ...button,
    textAlign: 'left',
  },
  forward: {
    ...button,
    textAlign: 'right',
  },
});
